<template>
    <!-- 工商 -->
    <div class="business">
        <!-- 头部 -->
        <TbusinCom1></TbusinCom1>
        <!-- 内容一 -->
        <TbusinCom2></TbusinCom2>
    </div>
</template>

<script>
import TbusinCom1 from "../components/content/Thebusiness/TbusinCom1.vue";
import TbusinCom2 from "../components/content/Thebusiness/TbusinCom2.vue";

export default {
    name: "Business",
    components: {
        TbusinCom1,
        TbusinCom2,
    },
};
</script>

<style lang="less" scoped>
.business {
    margin: 0 auto;
}
</style>
