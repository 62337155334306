<template>
  <div class="tbusinCom4">
    <div class="tc4Box1">
      <div class="tb1Title">
        <img
          src="https://cbs-public.cdn.bcebos.com/portal/20211129/pc_cbs_park_resource_banner.png"
          alt=""
          class="t1tBg"
        />
      </div>
      <div class="tc4Top">
        <p class="t4t1">工商财税热门服务</p>
        <p class="t4t2">精选最受欢迎的工商财税服务，解决您的创业难题</p>
      </div>
      <div class="tc4List">
        <ul>
          <li class="t4lLi" v-for="item in 3" :key="item">
            <div class="t4lBox">
              <div class="t4l1">
                <p class="t11">云上园区注册</p>
                <p class="t12">1元送刻章，送地址，可远程办理</p>
              </div>
              <div class="t4l2">
                <dl>
                  <dt v-for="item in 3" :key="item">
                    <img
                      src="https://cbs-public.cdn.bcebos.com/portal/20210804/dh.png"
                      alt=""
                      class="dtIcon"
                    />
                    <p class="dtp">全程线上操作，无需本人到场，方便快捷</p>
                  </dt>
                </dl>
              </div>
              <div class="t4l3">威有1起</div>
              <div class="t4l4">查看详情</div>
            </div>
          </li>
        </ul>
      </div>
    </div>
    <div class="tc4Box2">
      <div class="tb2Title">
        <div class="t2tP">
          <p class="ttp1">工商财税服务VIP急速办理通道</p>
          <p class="ttp2">
            免费提供全国30+城市公司注册、代理记账、资质代办全流程咨询服务
          </p>
          <p class="ttp3">立即预约</p>
        </div>
      </div>
      <div class="tb2Top">
        <p class="t2t1">公司经营一站式服务</p>
        <p class="t2t2">
          公司注册、变更、注销、记账报税、资质代办一站式服务、尽在威有智能云
        </p>
      </div>
      <div class="tb2Boxs" v-for="item in 3" :key="item">
        <div class="t2bTop">
          <img
            src="https://cbs-public.cdn.bcebos.com/portal/20210804/background-reg.png"
            alt=""
            class="t2bBg"
          />
          <p class="t2b1">工商注册</p>
          <p class="t2b2">工商专家为您省心办理，无隐形收费。</p>
        </div>
        <div class="t2bList">
          <dl>
            <dt v-for="item in 4" :key="item">
              <div class="dtBox">
                <p class="db1">本地公司注册</p>
                <p class="db2">
                  急速办理，最快3天下证，全程代办服务，专心省心！
                </p>
                <div class="db3">
                  <div class="d3bs" v-for="item in 3" :key="item">费用透明</div>
                </div>
                <p class="db4">威有9.9起</p>
              </div>
            </dt>
          </dl>
        </div>
      </div>
      <div class="tb2Btn">没有想要的服务？咨询更多服务内容</div>
    </div>
    <div class="tc4Box3">
      <div class="tb3Top">
        <p class="t3t1">公司注册经营初期流程</p>
        <p class="t3t2">
          业务办理流程及时间，因当地工商局政策不同存在差异，具体流程及时间请以实际为准，详情请咨询当地服务商
        </p>
      </div>
      <div class="tb3Img">
        <img
          src="https://cbs-public.cdn.bcebos.com/portal/20210804/process.png"
          alt=""
          class="tb3Bg"
        />
      </div>
    </div>
    <div class="tc4Box4">
      <div class="tb4Top">
        <p class="t4t1">热门城市工商注册解决方案</p>
        <p class="t4t2">提供热门城市工商注册一站式解决方案，解决公司起步难题</p>
      </div>
      <div class="tb4Texts">
        <div class="t4Left">
          <ul>
            <li class="t4lLi" v-for="item in 10" :key="item">
              <img
                src="https://cbs-public.cdn.bcebos.com/portal/20210804/position.png"
                alt=""
                class="t4icon"
              />
              <p class="t4p">北京市</p>
            </li>
          </ul>
        </div>
        <div class="t4Center">
          <img
            src="https://cbs-public.cdn.bcebos.com/portal/20210804/map.png"
            alt=""
            class="t4cimg"
          />
        </div>
        <div class="t4Right">
          <div class="t4rBox">
            <p class="t4r1">服务范围：</p>
            <div class="t4r2">
              <dl>
                <dt v-for="item in 6" :key="item">办理执照</dt>
              </dl>
            </div>
            <div class="t4r3">立即获取该城市报价+解决方案</div>
          </div>
        </div>
      </div>
    </div>
    <div class="tc4Box5">
      <div class="tb5Top">
        <p class="t5t1">办理材料</p>
        <p class="t5t2">
          工商注册所需材料及下发的相关证件，摆脱繁复，让创业更简单
        </p>
      </div>
      <div class="tb5Tab">
        <ul>
          <li
            class="t5tLi"
            @click="showT5s(1)"
            :class="{ t5Active: isActive === 1 }"
          >
            注册公司您需要准备材料
          </li>
          <li
            class="t5tLi"
            @click="showT5s(2)"
            :class="{ t5Active: isActive === 2 }"
          >
            注册成功后您将得到
          </li>
          <li
            class="t5tLi"
            @click="showT5s(3)"
            :class="{ t5Active: isActive === 3 }"
          >
            公司正式开展经营还需
          </li>
        </ul>
      </div>
      <div class="tb5TeImg" v-show="showT5 === 1">
        <img
          src="https://cbs-public.cdn.bcebos.com/portal/20210804/dszc.png"
          alt=""
          class="ttiBg"
        />
        <div class="ttiLeft">
          <dl>
            <dt v-for="item in 5" :key="item">
              <img
                src="https://cbs-public.cdn.bcebos.com/portal/20210804/icon1.png"
                alt=""
                class="dtIcon"
              />
              <div class="dtText">
                <p class="dtt1">核名</p>
                <p class="dtt2">2-5个名称字号</p>
              </div>
            </dt>
          </dl>
        </div>
      </div>
      <div class="tb5TeImg" v-show="showT5 === 2">
        <img
          src="https://cbs-public.cdn.bcebos.com/portal/20210804/dszc.png"
          alt=""
          class="ttiBg"
        />
        <div class="ttiLeft">
          <dl>
            <dt v-for="item in 5" :key="item">
              <img
                src="https://cbs-public.cdn.bcebos.com/portal/20210804/icon1.png"
                alt=""
                class="dtIcon"
              />
              <div class="dtText">
                <p class="dtt1">核名2</p>
                <p class="dtt2">2-5个名称字号</p>
              </div>
            </dt>
          </dl>
        </div>
      </div>
      <div class="tb5TeImg" v-show="showT5 === 3">
        <img
          src="https://cbs-public.cdn.bcebos.com/portal/20210804/dszc.png"
          alt=""
          class="ttiBg"
        />
        <div class="ttiLeft">
          <dl>
            <dt v-for="item in 5" :key="item">
              <img
                src="https://cbs-public.cdn.bcebos.com/portal/20210804/icon1.png"
                alt=""
                class="dtIcon"
              />
              <div class="dtText">
                <p class="dtt1">核名3</p>
                <p class="dtt2">2-5个名称字号</p>
              </div>
            </dt>
          </dl>
        </div>
      </div>
      <div class="tb5Btn">没有注册地址?立即咨询专业顾问解决</div>
    </div>
    <div class="tc4Box6">
      <div class="tb6Top">常见问题</div>
      <div class="tb6List">
        <ul>
          <li class="t6lLi" v-for="item in 5" :key="item">
            <div class="t6n">1</div>
            <div class="t6Text">
              <p class="t6t1">公司注册完成一般需要多久？</p>
              <p class="t6t2">
                一般为15-20个工作日。各地有不同，服务人员会跟您确认。
              </p>
            </div>
          </li>
        </ul>
      </div>
      <div class="tb6Btns">
        <div class="t6b1">定制私人服务</div>
        <div class="t6b2">咨询其他服务</div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "TbusinCom4",
  data() {
    return {
      showT5: 1,
      isActive: 1,
    };
  },
  methods: {
    showT5s(i) {
      this.showT5 = i;
      this.isActive = i;
    },
  },
};
</script>

<style lang="less" scoped>
.tbusinCom4 {
  width: 100%;
  margin: 0 auto;
  margin-top: 20px;
  .tc4Box1 {
    width: 100%;
    height: 700px;
    overflow: hidden;
    .tb1Title {
      width: 1280px;
      height: 100px;
      margin: 0 auto;
      .t1tBg {
        width: 100%;
        height: 100%;
      }
    }
    .tc4Top {
      width: 50%;
      height: 100px;
      margin: 0 auto;
      margin-top: 30px;
      text-align: center;
      .t4t1 {
        width: 100%;
        height: 50px;
        line-height: 50px;
        font-size: 30px;
      }
      .t4t2 {
        width: 100%;
        height: 22px;
        line-height: 22px;
        font-size: 16px;
        margin-top: 20px;
      }
    }
    .tc4List {
      width: 100%;
      height: 380px;
      margin-top: 50px;
      ul {
        width: 1280px;
        height: 100%;
        margin: 0 auto;
        display: flex;
        justify-content: space-around;
        .t4lLi {
          width: 380px;
          height: 380px;
          background-color: white;
          box-shadow: 1px 1px 10px 1px gainsboro;
          border-radius: 10px;
          .t4lBox {
            width: 90%;
            height: 90%;
            margin: 0 auto;
            margin-top: 5%;
            .t4l1 {
              width: 100%;
              height: 80px;
              overflow: hidden;
              border-bottom: 1px solid gainsboro;
              .t11 {
                width: 100%;
                height: 30px;
                line-height: 30px;
                font-size: 22px;
                margin-top: 10px;
              }
              .t12 {
                width: 100%;
                height: 22px;
                line-height: 22px;
                font-size: 14px;
                color: gray;
                margin-top: 5px;
              }
            }
            .t4l2 {
              width: 100%;
              height: 100px;
              margin-top: 10px;
              dl {
                width: 100%;
                height: 100%;
                display: flex;
                justify-content: space-around;
                flex-direction: column;
                dt {
                  width: 100%;
                  height: 22px;
                  line-height: 22px;
                  font-size: 16px;
                  .dtIcon {
                    width: 16px;
                    height: 11px;
                    float: left;
                    margin-top: 5px;
                  }
                  .dtp {
                    width: 310px;
                    font-size: 16px;
                    margin-left: 10px;
                    float: left;
                  }
                }
              }
            }
            .t4l3 {
              width: 100%;
              height: 40px;
              margin-top: 20px;
              line-height: 40px;
              font-size: 28px;
              color: #f27054;
            }
            .t4l4 {
              width: 300px;
              height: 45px;
              margin: 0 auto;
              margin-top: 20px;
              line-height: 45px;
              font-size: 16px;
              box-shadow: 1px 1px 10px 1px gainsboro;
              background-color: white;
              text-align: center;
              color: #2a62fe;
              border-radius: 10px;
            }
          }
        }
        .t4lLi:hover {
          box-shadow: 1px 1px 10px 1px gainsboro;
        }
      }
    }
  }
  .tc4Box2 {
    width: 100%;
    height: 1600px;
    margin-top: 30px;
    .tb2Title {
      width: 100%;
      height: 160px;
      background: url("https://cbs-public.cdn.bcebos.com/portal/20210804/background-1.png")
        no-repeat;
      .t2tP {
        width: 50%;
        height: 160px;
        margin: 0 auto;
        color: white;
        text-align: center;
        .ttp1 {
          width: 100%;
          height: 50px;
          line-height: 50px;
          font-size: 30px;
        }
        .ttp2 {
          width: 100%;
          height: 17px;
          line-height: 17px;
          font-size: 12px;
          margin-top: 10px;
        }
        .ttp3 {
          width: 120px;
          height: 36px;
          line-height: 36px;
          margin: 0 auto;
          margin-top: 20px;
          border-radius: 5px;
          background-color: white;
          color: #2a62fe;
          font-size: 14px;
          box-shadow: 1px 1px 10px 1px gainsboro;
        }
      }
    }
    .tb2Top {
      width: 50%;
      height: 80px;
      margin: 0 auto;
      margin-top: 50px;
      text-align: center;
      .t2t1 {
        width: 100%;
        height: 50px;
        line-height: 50px;
        font-size: 30px;
      }
      .t2t2 {
        width: 100%;
        height: 20px;
        line-height: 20px;
        margin-top: 10px;
      }
    }
    .tb2Boxs {
      width: 1280px;
      height: 328px;
      margin: 0 auto;
      margin-top: 50px;
      .t2bTop {
        width: 100%;
        height: 100px;
        position: relative;
        .t2bBg {
          width: 100%;
          height: 100%;
          position: absolute;
          left: 0;
        }
        .t2b1 {
          width: 300px;
          height: 38px;
          line-height: 38px;
          font-size: 22px;
          color: white;
          position: absolute;
          top: 10px;
          left: 30px;
        }
        .t2b2 {
          width: 300px;
          height: 18px;
          line-height: 18px;
          font-size: 14px;
          color: white;
          position: absolute;
          top: 50px;
          left: 30px;
        }
      }
      .t2bList {
        width: 100%;
        height: 228px;
        dl {
          width: 100%;
          height: 100%;
          display: flex;
          justify-content: space-around;
          margin: 0;
          dt {
            width: 25%;
            height: 100%;
            border: 1px solid gainsboro;
            border-top: none;
            overflow: hidden;
            .dtBox {
              width: 90%;
              height: 90%;
              margin: 0 auto;
              margin-top: 5%;
              .db1 {
                width: 100%;
                height: 25px;
                line-height: 25px;
                font-size: 18px;
              }
              .db2 {
                width: 100%;
                height: 40px;
                font-size: 14px;
                margin-top: 10px;
                color: gray;
              }
              .db3 {
                width: 100%;
                height: 22px;
                margin-top: 20px;
                .d3bs {
                  width: 66px;
                  height: 22px;
                  line-height: 22px;
                  text-align: center;
                  font-size: 12px;
                  border-radius: 5px;
                  border: 1px solid #2a62fe;
                  color: #2a62fe;
                  float: left;
                  background-color: rgba(36, 106, 245, 0.08);
                }
                .d3bs:nth-child(-n + 3) {
                  margin-left: 20px;
                }
              }
              .db4 {
                width: 100%;
                height: 37px;
                margin-top: 30px;
                color: #f27054;
                font-size: 28px;
              }
            }
          }
          dt:hover {
            box-shadow: 1px 1px 10px 1px gainsboro;
          }
        }
      }
    }
    .tb2Btn {
      width: 280px;
      height: 46px;
      line-height: 46px;
      text-align: center;
      font-size: 14px;
      color: white;
      background-color: #2a62fe;
      margin: 0 auto;
      margin-top: 50px;
      border-radius: 5px;
    }
  }
  .tc4Box3 {
    width: 100%;
    height: 422px;
    overflow: hidden;
    .tb3Top {
      width: 1280px;
      height: 80px;
      margin: 0 auto;
      margin-top: 30px;
      text-align: center;
      .t3t1 {
        width: 100%;
        height: 50px;
        line-height: 50px;
        font-size: 30px;
      }
      .t3t2 {
        width: 100%;
        height: 20px;
        line-height: 20px;
        color: gray;
        margin-top: 10px;
      }
    }
    .tb3Img {
      width: 1210px;
      height: 190px;
      margin: 0 auto;
      margin-top: 40px;
      .tb3Bg {
        width: 100%;
        height: 100%;
      }
    }
  }
  .tc4Box4 {
    width: 100%;
    height: 530px;
    .tb4Top {
      width: 1280px;
      height: 80px;
      margin: 0 auto;
      margin-top: 30px;
      text-align: center;
      .t4t1 {
        width: 100%;
        height: 50px;
        line-height: 50px;
        font-size: 30px;
      }
      .t4t2 {
        width: 100%;
        height: 20px;
        line-height: 20px;
        color: gray;
        margin-top: 10px;
      }
    }
    .tb4Texts {
      width: 1280px;
      height: 300px;
      margin: 0 auto;
      margin-top: 30px;
      display: flex;
      justify-content: space-around;
      .t4Left {
        width: 244px;
        height: 300px;
        ul {
          width: 100%;
          height: 100%;
          display: flex;
          justify-content: space-around;
          flex-wrap: wrap;
          .t4lLi {
            width: 48%;
            height: 42px;
            background-color: #2a62fe;
            color: white;
            border-radius: 5px;
            .t4icon {
              width: 10px;
              height: 14px;
              float: left;
              margin-top: 15px;
              margin-left: 20px;
            }
            .t4p {
              width: 48px;
              height: 22px;
              line-height: 22px;
              font-size: 16px;
              float: left;
              margin-left: 10px;
              margin-top: 10px;
            }
          }
        }
      }
      .t4Center {
        width: 383px;
        height: 300px;
        .t4cimg {
          width: 100%;
          height: 100%;
        }
      }
      .t4Right {
        width: 480px;
        height: 300px;
        box-shadow: 1px 1px 10px 1px gainsboro;
        border-radius: 10px;
        .t4rBox {
          width: 90%;
          height: 90%;
          margin: 0 auto;
          margin-top: 5%;
          .t4r1 {
            width: 100%;
            height: 30px;
            line-height: 30px;
            font-size: 22px;
          }
          .t4r2 {
            width: 100%;
            height: 102px;
            margin-top: 20px;
            dl {
              width: 100%;
              height: 100%;
              display: flex;
              justify-content: space-around;
              flex-wrap: wrap;
              dt {
                width: 118px;
                height: 36px;
                line-height: 36px;
                text-align: center;
                border-radius: 5px;
                border: 1px solid #2a62fe;
                color: #2a62fe;
              }
            }
          }
          .t4r3 {
            width: 100%;
            height: 46px;
            line-height: 46px;
            text-align: center;
            font-size: 12px;
            border-radius: 5px;
            background-color: #2a62fe;
            color: white;
            margin-top: 30px;
          }
        }
      }
    }
  }
  .tc4Box5 {
    width: 100%;
    height: 774px;
    overflow: hidden;
    .tb5Top {
      width: 1280px;
      height: 80px;
      margin: 0 auto;
      margin-top: 30px;
      text-align: center;
      .t5t1 {
        width: 100%;
        height: 50px;
        line-height: 50px;
        font-size: 30px;
      }
      .t5t2 {
        width: 100%;
        height: 20px;
        line-height: 20px;
        color: gray;
        margin-top: 10px;
      }
    }
    .tb5Tab {
      width: 1210px;
      height: 50px;
      margin: 0 auto;
      margin-top: 30px;
      ul {
        width: 100%;
        height: 100%;
        display: flex;
        justify-content: space-around;
        .t5tLi {
          width: 393px;
          height: 50px;
          line-height: 50px;
          text-align: center;
          font-size: 18px;
          cursor: pointer;
        }
        .t5Active {
          color: white;
          background-color: #2a62fe;
        }
      }
    }
    .tb5TeImg {
      width: 1210px;
      height: 420px;
      margin: 0 auto;
      margin-top: 30px;
      position: relative;
      .ttiBg {
        width: 100%;
        height: 100%;
      }
      .ttiLeft {
        width: 50%;
        height: 100%;
        position: absolute;
        top: 0;
        left: 0;
        dl {
          width: 80%;
          height: 90%;
          margin-left: 20%;
          margin-top: 5%;
          display: flex;
          justify-content: space-around;
          flex-direction: column;
          dt {
            width: 100%;
            height: 75px;
            .dtIcon {
              width: 16px;
              height: 16px;
              float: left;
              margin-top: 5px;
            }
            .dtText {
              min-width: 390px;
              height: 100%;
              float: left;
              margin-left: 15px;
              .dtt1 {
                width: 100%;
                height: 24px;
                line-height: 24px;
                font-size: 18px;
              }
              .dtt2 {
                width: 100%;
                height: 20px;
                line-height: 20px;
                font-size: 14px;
                margin-top: 20px;
                color: gray;
              }
            }
          }
        }
      }
    }
    .tb5Btn {
      width: 320px;
      height: 46px;
      line-height: 46px;
      text-align: center;
      color: white;
      font-size: 14px;
      margin: 0 auto;
      margin-top: 40px;
      border-radius: 5px;
      background-color: #2a62fe;
    }
  }
  .tc4Box6 {
    width: 100%;
    height: 723px;
    overflow: hidden;
    .tb6Top {
      width: 50%;
      height: 40px;
      line-height: 40px;
      font-size: 28px;
      margin: 0 auto;
      margin-top: 30px;
      text-align: center;
    }
    .tb6List {
      width: 1000px;
      height: 500px;
      margin: 0 auto;
      margin-top: 30px;
      ul {
        width: 100%;
        height: 100%;
        display: flex;
        justify-content: space-around;
        flex-direction: column;
        .t6lLi {
          width: 100%;
          height: 55px;
          .t6n {
            width: 28px;
            height: 28px;
            line-height: 28px;
            text-align: center;
            border-radius: 50%;
            background-color: #2a62fe;
            color: white;
            float: left;
          }
          .t6Text {
            min-width: 760px;
            height: 100%;
            float: left;
            margin-left: 10px;
            .t6t1 {
              width: 100%;
              height: 24px;
              line-height: 24px;
              font-size: 18px;
            }
            .t6t2 {
              width: 100%;
              height: 20px;
              line-height: 20px;
              font-size: 14px;
              margin-top: 10px;
              color: gray;
            }
          }
        }
      }
    }
    .tb6Btns {
      width: 40%;
      height: 50px;
      line-height: 50px;
      text-align: center;
      color: white;
      margin: 0 auto;
      margin-top: 30px;
      display: flex;
      justify-content: space-around;
      .t6b1 {
        width: 220px;
        height: 50px;
        background-color: #fda003;
        border-radius: 5px;
      }
      .t6b2 {
        width: 220px;
        height: 50px;
        background-color: #2a62fe;
        border-radius: 5px;
      }
    }
  }
}
</style>
