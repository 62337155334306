import { render, staticRenderFns } from "./TbusinCom2.vue?vue&type=template&id=33e1a8ca&scoped=true&"
import script from "./TbusinCom2.vue?vue&type=script&lang=js&"
export * from "./TbusinCom2.vue?vue&type=script&lang=js&"
import style0 from "./TbusinCom2.vue?vue&type=style&index=0&id=33e1a8ca&lang=less&scoped=true&"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "33e1a8ca",
  null
  
)

export default component.exports