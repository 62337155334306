<template>
  <div class="tbusinCom3">
    <div class="tc3Box">
      <div class="t3Box1">
        <div class="tb1Top">
          <p class="t1t1">公司设立后续服务</p>
          <p class="t1t2">
            可按需选择套餐，含银行开户、税务报道、代理记账等服务
          </p>
        </div>
        <div class="tb1Box">
          <ul>
            <li class="t1bLi" v-for="item in 2" :key="item">
              <div class="t1lLeft">
                <img
                  src="https://cbs-public.bj.bcebos.com/portal/20220617/header-package-bg.png"
                  alt=""
                  class="tllBg"
                />
                <div class="tllt">
                  <p class="tllp">初创公司套餐</p>
                  <div class="tllbs">
                    <div class="tbs" v-for="item in 3" :key="item">
                      园区代理
                    </div>
                  </div>
                </div>
              </div>
              <div class="t1lCenter">
                <div class="tlcBox">
                  <div class="tcbs">
                    <p class="tcbp">选择园区</p>
                    <div class="tcbIp">
                      <select>
                        <option value="1">中山园区</option>
                        <option value="2">杭州园区</option>
                      </select>
                    </div>
                  </div>
                  <div class="tcbs">
                    <p class="tcbp">选择类型</p>
                    <div class="tcbIp">
                      <select>
                        <option value="1">内资有限公司（小规模纳税人）</option>
                        <option value="2">内资有限公司（一般纳税人）</option>
                      </select>
                    </div>
                  </div>
                </div>
              </div>
              <div class="t1lRight">
                <div class="tlrBox">
                  <div class="trb1">立省1000元</div>
                  <div class="trb2">
                    <p class="tr21">2599元/年起</p>
                    <p class="tr22">3599元/年起</p>
                  </div>
                  <div class="trb3">
                    <div class="tr3b">免费咨询</div>
                    <div class="tr3b">立即下单</div>
                  </div>
                </div>
              </div>
            </li>
          </ul>
        </div>
      </div>
      <div class="t3Box2">
        <div class="tb2Top">
          <p class="t2t1">园区注册优势</p>
          <p class="t2t2">大幅度降低创业者时间成本与注册费用</p>
        </div>
        <div class="tb2Texts">
          <div class="t2tleft">
            <img
              src="https://cbs-public.bj.bcebos.com/portal/20220617/advantage-cloudpark.png"
              alt=""
              class="t2lBg"
            />
            <div class="tlPs">
              <p class="tp1">云上园区注册</p>
              <ul>
                <li class="tpLi" v-for="item in 4" :key="item">
                  <img
                    src="https://cbs-public.bj.bcebos.com/portal/20220617/label-cloudpark.png"
                    alt=""
                    class="tplI"
                  />
                  <p class="tplp">无需提供注册地址，有效节省创业初期运营成本</p>
                </li>
              </ul>
            </div>
            <img
              src="https://cbs-public.bj.bcebos.com/portal/20220617/VS.png"
              alt=""
              class="tlpvs"
            />
          </div>
          <div class="t2tRight">
            <div class="trps">
              <p class="trp1">普通公司注册</p>
              <ul>
                <li class="tpLi" v-for="item in 4" :key="item">
                  <img
                    src="https://cbs-public.bj.bcebos.com/portal/20220617/label-normal.png"
                    alt=""
                    class="tplI"
                  />
                  <p class="tplp">需要提供注册地址，对于企业初期费用成本高</p>
                </li>
              </ul>
            </div>
          </div>
        </div>
      </div>
      <div class="t3Box3">
        <div class="tb3Top">
          <p class="t3t1">平台注册优势</p>
          <p class="t3t2">金牌服务质量，优质服务体验</p>
        </div>
        <div class="tb3List">
          <ul>
            <li class="t3Li" v-for="item in 4" :key="item">
              <div class="t3l1">
                <img
                  src="https://cbs-public.bj.bcebos.com/portal/20220617/main-park.png"
                  alt=""
                  class="t31Img"
                />
              </div>
              <p class="t3l2">覆盖国内主要园区</p>
              <p class="t3l3">
                全国热门城市全覆盖，支持有限公司及个体户入驻，免费提供注册地址（首年），
                北京部分地址可永久使用
              </p>
            </li>
          </ul>
        </div>
      </div>
      <div class="t3Box4">
        <div class="tb4Top">
          <p class="t4t1">园区入驻常见问题</p>
          <p class="t4t2">
            各大园区详细政策请点击 <a href="">查看全部园区政策</a
            ><i class="iconfont icon-youbianjiantou"></i>
          </p>
        </div>
        <div class="tb4List">
          <ul>
            <li
              class="tb4Li"
              v-for="item in 5"
              :key="item"
              :class="{ openTb4: opTb4 === item }"
            >
              <div class="tb4Box">
                <div class="t4bn">1</div>
                <p class="t4bp">云上注册是什么？跟普通注册的区别？</p>
                <p class="t4bp2">
                  云上注册主要针对创业时期无需租用场地或找办公室的群体，
                  可以依托园区合法地址快速注册营业执照，并且可以享受园区的各种税收优惠、中小企业扶持政策等，是电商行业、
                  微小企业、个体户的最佳选择。反之，普通注册需要实地租赁地址办公，租金跟随各地政策、房价有所不同，且
                  无法享受园区税收政策。
                </p>
              </div>
              <i class="iconfont icon-xiala" @click="opTb4s(item)"></i>
            </li>
          </ul>
        </div>
      </div>
      <div class="t3Box5">
        <div class="tb5Top">
          <p class="t5t1">工商其他单品/服务推荐</p>
          <p class="t5t2">提供一站式初创企业解决方案</p>
        </div>
        <div class="tb5List">
          <ul>
            <li class="tb5Li" v-for="item in 4" :key="item">
              <img
                src="https://cbs-public.cdn.bcebos.com/portal/20220617/recommend-crs.png"
                alt=""
                class="tb5Bg"
              />
              <div class="tb5Text">
                <p class="t5t1">本地公司注册</p>
                <p class="t5t2">27城市9.9元代办注册（自备注册地址）</p>
                <p class="t5t3">
                  查看详情<i class="iconfont icon-youbianjiantou"></i>
                </p>
              </div>
            </li>
          </ul>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "TbusinCom3",
  data() {
    return {
      opTb4: 1,
    };
  },
  methods: {
    opTb4s(i) {
      this.opTb4 = i;
    },
  },
};
</script>

<style lang="less" scoped>
.tbusinCom3 {
  width: 100%;
  margin: 0 auto;
  background: url("https://cbs-public.bj.bcebos.com/portal/20220617/advantage-bg.png");
  .tc3Box {
    width: 1280px;
    height: 100%;
    margin: 0 auto;
    .t3Box1 {
      width: 100%;
      height: 540px;
      overflow: hidden;
      .tb1Top {
        width: 50%;
        height: 70px;
        margin: 0 auto;
        margin-top: 30px;
        text-align: center;
        .t1t1 {
          width: 100%;
          height: 28px;
          line-height: 28px;
          font-size: 28px;
          color: #1e3f82;
          font-weight: 900;
        }
        .t1t2 {
          width: 100%;
          height: 16px;
          line-height: 16px;
          font-size: 14px;
          margin-top: 20px;
          color: #1e3f82;
        }
      }
      .tb1Box {
        width: 1220px;
        height: 340px;
        margin: 0 auto;
        margin-top: 50px;
        ul {
          width: 100%;
          height: 100%;
          box-shadow: 1px 1px 10px 1px gainsboro;
          border-radius: 10px;
          .t1bLi {
            width: 100%;
            height: 50%;
            background-color: white;
            .t1lLeft {
              width: 25%;
              height: 100%;
              position: relative;
              float: left;
              .tllBg {
                width: 100%;
                height: 100%;
                position: absolute;
                top: 0;
              }
              .tllt {
                width: 90%;
                height: 80px;
                color: white;
                position: absolute;
                top: 40px;
                left: 15px;
                .tllp {
                  width: 100%;
                  height: 28px;
                  line-height: 28px;
                  font-size: 18px;
                  color: white;
                }
                .tllbs {
                  width: 100%;
                  height: 22px;
                  margin-top: 20px;
                  .tbs {
                    width: 65px;
                    height: 22px;
                    color: white;
                    font-size: 12px;
                    border: 1px solid white;
                    text-align: center;
                    line-height: 22px;
                    float: left;
                    margin-left: 5px;
                    border-radius: 5px;
                  }
                }
              }
            }
            .t1lCenter {
              width: 55%;
              height: 100%;
              float: left;
              .tlcBox {
                width: 90%;
                height: 90%;
                margin-left: 35px;
                margin-top: 8px;
                .tcbs {
                  width: 50%;
                  height: 100%;
                  float: left;
                  .tcbp {
                    width: 100%;
                    height: 24px;
                    line-height: 24px;
                    font-size: 14px;
                    margin-top: 30px;
                  }
                  .tcbIp {
                    width: 90%;
                    height: 40px;
                    margin-top: 20px;
                    select {
                      width: 100%;
                      height: 100%;
                    }
                  }
                }
              }
            }
            .t1lRight {
              width: 20%;
              height: 100%;
              float: left;
              .tlrBox {
                width: 90%;
                height: 90%;
                margin: 0 auto;
                margin-top: 10px;
                border-left: 1px solid rgba(36, 105, 243, 0.2);
                padding-left: 15px;
                .trb1 {
                  width: 82px;
                  height: 24px;
                  line-height: 24px;
                  text-align: center;
                  color: white;
                  background-color: #f33e3e;
                  border-radius: 5px;
                  font-size: 14px;
                }
                .trb2 {
                  width: 100%;
                  height: 32px;
                  margin-top: 20px;
                  .tr21 {
                    width: 100px;
                    height: 32px;
                    line-height: 32px;
                    color: #f43f3f;
                    float: left;
                  }
                  .tr22 {
                    width: 100px;
                    height: 24px;
                    line-height: 24px;
                    font-size: 14px;
                    text-decoration: line-through;
                    float: left;
                    margin-top: 5px;
                    margin-left: 5px;
                  }
                }
                .trb3 {
                  width: 100%;
                  height: 38px;
                  margin-top: 20px;
                  .tr3b {
                    width: 100px;
                    height: 38px;
                    line-height: 38px;
                    color: #f43f3f;
                    border: 1px solid #f43f3f;
                    text-align: center;
                    font-size: 14px;
                    float: left;
                  }
                  .tr3b:nth-child(2) {
                    margin-left: 10px;
                  }
                }
              }
            }
          }
          .t1bLi:nth-child(1) {
            border-bottom: 1px solid rgba(36, 105, 243, 0.2);
          }
        }
      }
    }
    .t3Box2 {
      width: 100%;
      height: 488px;
      overflow: hidden;
      .tb2Top {
        width: 50%;
        height: 70px;
        margin: 0 auto;
        margin-top: 30px;
        text-align: center;
        .t2t1 {
          width: 100%;
          height: 28px;
          line-height: 28px;
          font-size: 28px;
          color: #1e3f82;
          font-weight: 900;
        }
        .t2t2 {
          width: 100%;
          height: 16px;
          line-height: 16px;
          font-size: 14px;
          margin-top: 20px;
          color: #1e3f82;
        }
      }
      .tb2Texts {
        width: 1220px;
        height: 288px;
        margin: 0 auto;
        margin-top: 40px;
        .t2tleft {
          width: 55%;
          height: 100%;
          float: left;
          position: relative;
          .t2lBg {
            width: 100%;
            height: 100%;
          }
          .tlPs {
            width: 464px;
            height: 192px;
            position: absolute;
            top: 40px;
            left: 30px;
            color: white;
            .tp1 {
              width: 100%;
              height: 28px;
              line-height: 28px;
              font-size: 18px;
            }
            ul {
              width: 100%;
              height: 148px;
              margin-top: 20px;
              display: flex;
              justify-content: space-around;
              flex-wrap: wrap;
              .tpLi {
                width: 100%;
                height: 24px;
                line-height: 24px;
                .tplI {
                  width: 14px;
                  height: 14px;
                  float: left;
                  margin-top: 5px;
                }
                .tplp {
                  min-width: 200px;
                  height: 24px;
                  margin-left: 20px;
                }
              }
              .tlpvs {
              }
            }
          }
        }
        .t2tRight {
          width: 45%;
          height: 90%;
          float: left;
          margin-left: -32px;
          margin-top: 15px;
          background-color: #cbe3ff;
          .trps {
            width: 464px;
            height: 192px;
            margin-left: 50px;
            margin-top: 30px;
            .tp1 {
              width: 100%;
              height: 28px;
              line-height: 28px;
              font-size: 18px;
            }
            ul {
              width: 100%;
              height: 148px;
              margin-top: 20px;
              display: flex;
              justify-content: space-around;
              flex-wrap: wrap;
              .tpLi {
                width: 100%;
                height: 24px;
                line-height: 24px;
                .tplI {
                  width: 14px;
                  height: 14px;
                  float: left;
                  margin-top: 5px;
                }
                .tplp {
                  min-width: 200px;
                  height: 24px;
                  margin-left: 20px;
                }
              }
              .tlpvs {
              }
            }
          }
        }
      }
    }
    .t3Box3 {
      width: 100%;
      height: 420px;
      overflow: hidden;
      .tb3Top {
        width: 50%;
        height: 70px;
        margin: 0 auto;
        margin-top: 30px;
        text-align: center;
        .t3t1 {
          width: 100%;
          height: 28px;
          line-height: 28px;
          font-size: 28px;
          color: #1e3f82;
          font-weight: 900;
        }
        .t3t2 {
          width: 100%;
          height: 16px;
          line-height: 16px;
          font-size: 14px;
          margin-top: 20px;
          color: #1e3f82;
        }
      }
      .tb3List {
        width: 100%;
        height: 220px;
        margin-top: 40px;
        ul {
          width: 100%;
          height: 100%;
          display: flex;
          justify-content: space-around;
          flex-wrap: wrap;
          .t3Li {
            width: 23%;
            height: 220px;
            .t3l1 {
              width: 100%;
              height: 92px;
              .t31Img {
                width: 92px;
                height: 92px;
                margin: 0 auto;
                display: block;
              }
            }
            .t3l2 {
              width: 100%;
              height: 28px;
              line-height: 28px;
              font-size: 18px;
              text-align: center;
              margin-top: 10px;
            }
            .t3l3 {
              width: 100%;
              height: 72px;
              font-size: 14px;
              margin-top: 10px;
              text-align: center;
            }
          }
        }
      }
    }
    .t3Box4 {
      width: 100%;
      height: 820px;
      overflow: hidden;
      .tb4Top {
        width: 50%;
        height: 70px;
        margin: 0 auto;
        margin-top: 30px;
        text-align: center;
        .t4t1 {
          width: 100%;
          height: 28px;
          line-height: 28px;
          font-size: 28px;
          color: #1e3f82;
          font-weight: 900;
        }
        .t4t2 {
          width: 100%;
          height: 16px;
          line-height: 16px;
          font-size: 14px;
          margin-top: 20px;
          color: #1e3f82;
          a,
          .iconfont {
            color: #7781f1;
          }
        }
      }
      .tb4List {
        width: 100%;
        height: 600px;
        margin-top: 50px;
        ul {
          width: 100%;
          height: 100%;
          display: flex;
          flex-wrap: wrap;
          justify-content: space-around;
          .tb4Li {
            width: 95%;
            height: 84px;
            box-shadow: 1px 1px 10px 1px gainsboro;
            border-radius: 5px;
            background-color: #e0efff;
            overflow: hidden;
            margin: 0 auto;
            position: relative;
            .tb4Box {
              width: 90%;
              height: 80px;
              margin: 0 auto;
              margin-top: 30px;
              .t4bn {
                width: 22px;
                height: 22px;
                margin-top: 5px;
                text-align: center;
                color: white;
                font-size: 14px;
                border-radius: 50%;
                background-color: #2469f3;
                float: left;
              }
              .t4bp {
                width: 96%;
                height: 28px;
                line-height: 28px;
                font-size: 18px;
                margin-left: 20px;
                float: left;
              }
              .t4bp2 {
                width: 96%;
                height: 48px;
                line-height: 24px;
                font-size: 14px;
                padding-left: 40px;
                float: left;
                color: gray;
                margin-top: 5px;
                display: none;
              }
            }
            .iconfont {
              position: absolute;
              right: 20px;
              top: 30px;
              font-size: 25px;
              font-weight: 900;
              cursor: pointer;
              transition: 0.5s;
              color: gray;
            }
          }
          .openTb4 {
            transition: 0.5s;
            height: 140px;
            .tb4Box .t4bp2 {
              display: block;
            }
            .iconfont {
              transform: rotate(180deg);
            }
          }
        }
      }
    }
    .t3Box5 {
      width: 100%;
      height: 556px;
      overflow: hidden;
      .tb5Top {
        width: 50%;
        height: 70px;
        margin: 0 auto;
        margin-top: 30px;
        text-align: center;
        .t5t1 {
          width: 100%;
          height: 28px;
          line-height: 28px;
          font-size: 28px;
          color: #1e3f82;
          font-weight: 900;
        }
        .t5t2 {
          width: 100%;
          height: 16px;
          line-height: 16px;
          font-size: 14px;
          margin-top: 20px;
          color: #1e3f82;
        }
      }
      .tb5List {
        width: 100%;
        height: 376px;
        margin-top: 50px;
        ul {
          width: 100%;
          height: 100%;
          display: flex;
          justify-content: space-around;
          flex-wrap: wrap;
          .tb5Li {
            width: 48%;
            height: 45%;
            border-radius: 5px;
            position: relative;
            transition: 0.5s;
            .tb5Bg {
              width: 100%;
              height: 100%;
            }
            .tb5Text {
              width: 350px;
              height: 150px;
              position: absolute;
              top: 15px;
              left: 30px;
              .t5t1 {
                width: 100%;
                height: 28px;
                line-height: 28px;
                font-size: 20px;
                color: #09429f;
              }
              .t5t2 {
                width: 100%;
                height: 19px;
                line-height: 19px;
                color: #09429f;
                font-size: 14px;
                margin-top: 20px;
              }
              .t5t3 {
                width: 100%;
                height: 26px;
                line-height: 26px;
                font-size: 16px;
                color: #2469f3;
                margin-top: 40px;
                .iconfont {
                  color: #2469f3;
                  margin-left: 5px;
                }
              }
            }
          }
          .tb5Li:hover {
            transform: translateY(-10px);

            box-shadow: 1px 1px 10px 1px gainsboro;
          }
        }
      }
    }
  }
}
</style>
