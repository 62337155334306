<template>
  <div class="tbusinCom2">
    <div class="tc2Box">
      <!-- tab切换 -->
      <div class="tc2Tab">
        <div
          class="t2Btn"
          @click="showTcs(1)"
          :class="{ t2Active: isActive === 1 }"
        >
          云上注册
        </div>
        <div
          class="t2Btn"
          @click="showTcs(2)"
          :class="{ t2Active: isActive === 2 }"
        >
          普通注册
        </div>
      </div>
      <!-- 内容一 -->
      <div class="tc2Content1" v-show="showTc === 1">
        <!-- 精选 -->
        <div class="tcBox1">
          <div class="tcbTop">
            <p class="tbt1">精选热门园区注册</p>
            <p class="tbt2">提供营业执照代办、注册地址、刻章服务</p>
          </div>
          <div class="tcbList">
            <ul>
              <li class="tblLi" v-for="item in 6" :key="item">
                <div class="tl1">
                  <img
                    src="https://cbs-public.bj.bcebos.com/portal/20220617/header-single-bg.png"
                    alt=""
                    class="tl1Bg"
                  />
                  <p class="tl1p">上海园区</p>
                  <div class="tl1bs">
                    <div class="tl1b" v-for="item in 3" :key="item">
                      足不出户
                    </div>
                  </div>
                </div>
                <div class="tl2">
                  <dl class="tl21">
                    <dt v-for="item in 4" :key="item">
                      <img
                        src="https://cbs-public.bj.bcebos.com/portal/20220617/label-checked.png"
                        alt=""
                        class="dtImg"
                      />
                      <p class="dtp">免费注册地址（首年）</p>
                    </dt>
                  </dl>
                  <div class="tl22">
                    <p class="t22p">选择类型</p>
                    <div class="t22Ip">
                      <select>
                        <option value="1">内资有限公司（小规模纳税人）</option>
                        <option value="2">内资有限公司（一般纳税人）</option>
                      </select>
                    </div>
                  </div>
                </div>
                <div class="tl3">
                  <div class="tl31">立省679元</div>
                  <div class="tl32">
                    <p class="tl3p">1元/年起</p>
                    <p class="tl3p2">680元/年起</p>
                  </div>
                  <div class="tl33">
                    <div class="tl3b">免费咨询</div>
                    <div class="tl3b">立即下单</div>
                  </div>
                </div>
              </li>
            </ul>
          </div>
          <div class="tcbMore">
            查看更多优质区资源<i class="iconfont icon-xiala"></i>
          </div>
        </div>
        <!-- 注册 -->
        <div class="tcBox2">
          <div class="tcbTop">
            <p class="tbt1">北京园区公司注册</p>
            <p class="tbt2">区域税收优惠政策，专项产业发展扶持</p>
          </div>
          <div class="tcbList">
            <ul>
              <li class="tblLi" v-for="item in 3" :key="item">
                <div class="tl1">
                  <img
                    src="https://cbs-public.bj.bcebos.com/portal/20220617/header-single-bg.png"
                    alt=""
                    class="tl1Bg"
                  />
                  <p class="tl1p">北京房山园区</p>
                  <div class="tl1bs">
                    <div class="tl1b" v-for="item in 3" :key="item">
                      足不出户
                    </div>
                  </div>
                </div>
                <div class="tl2">
                  <dl class="tl21">
                    <dt v-for="item in 4" :key="item">
                      <img
                        src="https://cbs-public.bj.bcebos.com/portal/20220617/label-checked.png"
                        alt=""
                        class="dtImg"
                      />
                      <p class="dtp">免费注册地址（首年）</p>
                    </dt>
                  </dl>
                  <div class="tl22">
                    <p class="t22p">选择类型</p>
                    <div class="t22Ip">
                      <select>
                        <option value="1">内资有限公司（小规模纳税人）</option>
                        <option value="2">内资有限公司（一般纳税人）</option>
                      </select>
                    </div>
                  </div>
                </div>
                <div class="tl3">
                  <div class="tl31">立省1948元</div>
                  <div class="tl32">
                    <p class="tl3p">1672元/年起</p>
                    <p class="tl3p2">3620元/年起</p>
                  </div>
                  <div class="tl33">
                    <div class="tl3b">免费咨询</div>
                    <div class="tl3b">立即下单</div>
                  </div>
                </div>
              </li>
            </ul>
          </div>
          <div class="tcbBottom">
            <p class="tcbp">北京园区入驻优势</p>
            <div class="tbList">
              <ul>
                <li class="tbLi" v-for="item in 4" :key="item">
                  <div class="tblBox">
                    <div class="tb1">
                      <img
                        src="https://cbs-public.bj.bcebos.com/portal/20220617/advantage-butie.png"
                        alt=""
                        class="tb1Img"
                      />
                    </div>
                    <p class="tb2">官方注册补贴</p>
                    <p class="tb3">
                      官方提供注册地址补贴，助力千万创业者实现创业梦想
                    </p>
                  </div>
                </li>
              </ul>
            </div>
            <div class="tcbBtn">
              <p class="tbp">立即申请入园</p>
              <img
                src="https://cbs-public.bj.bcebos.com/portal/20220623/arrow-right.png"
                alt=""
                class="tbIcon"
              />
            </div>
          </div>
        </div>
        <!-- 流程图 -->
        <div class="tc2Pic">
          <div class="t2pBox">
            <div class="t2pTop">
              <p class="tpt1">公司注册流程</p>
              <p class="tpt2">绿色通道注册，快至3-7天拿证营业</p>
            </div>
            <div class="t2pImg">
              <img
                src="https://cbs-public.bj.bcebos.com/portal/20220617/flow.png"
                alt=""
                class="t2pI"
              />
            </div>
          </div>
        </div>
        <TbusinCom3></TbusinCom3>
      </div>
      <!-- 内容二 -->
      <div class="tc2Content2" v-show="showTc === 2">
        <TbusinCom4></TbusinCom4>
      </div>
    </div>
  </div>
</template>

<script>
import TbusinCom1 from "./TbusinCom1.vue";
import TbusinCom3 from "./TbusinCom3.vue";
import TbusinCom4 from "./TbusinCom4.vue";

export default {
  name: "tbusinCom2",
  data() {
    return {
      showTc: 1,
      isActive: 1,
    };
  },
  methods: {
    showTcs(i) {
      this.showTc = i;
      this.isActive = i;
    },
  },
  components: {
    TbusinCom3,
    TbusinCom4,
    TbusinCom1,
  },
};
</script>

<style lang="less" scoped>
.tbusinCom2 {
  width: 100%;
  background-color: #e6f1ff;
  .tc2Box {
    width: 100%;
    // height: 2600px;
    margin: 0 auto;
    // tab切换
    .tc2Tab {
      width: 30%;
      height: 80px;
      margin: 0 auto;
      display: flex;
      justify-content: space-around;
      .t2Btn {
        width: 150px;
        height: 40px;
        line-height: 40px;
        font-size: 18px;
        text-align: center;
        border-radius: 20px;
        margin-top: 20px;
        box-shadow: 1px 1px 10px 1px gray;
        cursor: pointer;
      }
      .t2Active {
        background-color: #2469f3;
        color: white;
      }
    }
    // 内容一
    .tc2Content1 {
      width: 100%;
      margin-top: 20px;
      // 精选
      .tcBox1 {
        width: 1210px;
        margin: 0 auto;
        height: 1150px;
        // 标题
        .tcbTop {
          width: 40%;
          height: 60px;
          margin: 0 auto;
          text-align: center;
          color: #1e3f82;
          .tbt1 {
            width: 100%;
            height: 28px;
            line-height: 28px;
            font-size: 28px;
            font-weight: 900;
          }
          .tbt2 {
            width: 100%;
            height: 16px;
            line-height: 16px;
            font-size: 14px;
            margin-top: 15px;
          }
        }
        // 列表
        .tcbList {
          width: 100%;
          height: 1000px;
          margin-top: 30px;
          ul {
            width: 100%;
            height: 100%;
            display: flex;
            justify-content: space-around;
            flex-wrap: wrap;
            .tblLi {
              width: 32%;
              height: 478px;
              border-radius: 10px;
              transition: 0.5s;
              background-color: white;
              box-shadow: 1px 1px 10px 1px gainsboro;
              // 列表头部
              .tl1 {
                width: 100%;
                height: 128px;
                overflow: hidden;
                position: relative;
                .tl1Bg {
                  width: 100%;
                  height: 100%;
                  position: absolute;
                  top: 0;
                }
                .tl1p {
                  width: 90%;
                  height: 28px;
                  line-height: 28px;
                  font-size: 18px;
                  position: absolute;
                  top: 20px;
                  left: 20px;
                  color: white;
                }
                .tl1bs {
                  width: 90%;
                  height: 22px;
                  position: absolute;
                  top: 70px;
                  left: 0px;
                  .tl1b {
                    width: 66px;
                    height: 22px;
                    color: white;
                    font-size: 12px;
                    border: 1px solid white;
                    text-align: center;
                    line-height: 22px;
                    float: left;
                    margin-left: 20px;
                    border-radius: 5px;
                  }
                }
              }
              // 选项
              .tl2 {
                width: 90%;
                height: 190px;
                margin: 0 auto;
                .tl21 {
                  width: 100%;
                  height: 152px;
                  display: flex;
                  justify-content: space-around;
                  flex-wrap: wrap;
                  dt {
                    width: 100%;
                    height: 24px;
                    .dtImg {
                      width: 14px;
                      height: 14px;
                      float: left;
                      margin-top: 5px;
                    }
                    .dtp {
                      min-width: 140px;
                      height: 24px;
                      line-height: 24px;
                      float: left;
                      margin-left: 10px;
                    }
                  }
                }
                .tl22 {
                  width: 100%;
                  height: 40px;
                  .t22p {
                    width: 56px;
                    height: 38px;
                    line-height: 38px;
                    font-size: 12px;
                    color: gray;
                    float: left;
                  }
                  .t22Ip {
                    width: 260px;
                    height: 38px;
                    float: left;
                    margin-left: 25px;
                    select {
                      width: 100%;
                      height: 100%;
                    }
                  }
                }
              }
              // 价格
              .tl3 {
                width: 90%;
                height: 120px;
                margin: 0 auto;
                margin-top: 20px;
                overflow: hidden;
                .tl31 {
                  width: 75px;
                  height: 24px;
                  line-height: 24px;
                  color: white;
                  border-radius: 5px;
                  text-align: center;
                  font-size: 12px;
                  background-color: #f33e3e;
                  margin-top: 5px;
                }
                .tl32 {
                  width: 100%;
                  height: 32px;
                  line-height: 32px;
                  margin-top: 5px;
                  .tl3p {
                    width: 100px;
                    height: 32px;
                    font-size: 20px;
                    float: left;
                    color: #f43f3f;
                  }
                  .tl3p2 {
                    width: 100px;
                    height: 24px;
                    margin-top: 8px;
                    line-height: 24px;
                    font-size: 14px;
                    float: left;
                    color: gray;
                    text-decoration: line-through;
                  }
                }
                .tl33 {
                  width: 100%;
                  height: 40px;
                  margin-top: 5px;
                  .tl3b {
                    width: 158px;
                    height: 40px;
                    line-height: 40px;
                    text-align: center;
                    color: #f43f3f;
                    font-size: 14px;
                    border-radius: 5px;
                    border: 1px solid #f43f3f;
                    float: left;
                    margin-left: 10px;
                  }
                }
              }
            }
            .tblLi:hover {
              transform: translateY(-15px);
            }
          }
        }
        .tcbMore {
          width: 30%;
          height: 24px;
          line-height: 24px;
          text-align: center;
          font-size: 14px;
          color: #2469f3;
          margin: 0 auto;
          margin-top: 10px;
          .icon-xiala {
            color: #2469f3;
            margin-left: 10px;
          }
        }
      }
      // 注册
      .tcBox2 {
        width: 100%;
        height: 1000px;
        margin-top: 20px;
        overflow: hidden;
        background: url("https://cbs-public.bj.bcebos.com/portal/20220617/advantage-bg.png");
        // 标题
        .tcbTop {
          width: 40%;
          height: 60px;
          margin: 0 auto;
          margin-top: 30px;
          text-align: center;
          color: #1e3f82;
          .tbt1 {
            width: 100%;
            height: 28px;
            line-height: 28px;
            font-size: 28px;
            font-weight: 900;
          }
          .tbt2 {
            width: 100%;
            height: 16px;
            line-height: 16px;
            font-size: 14px;
            margin-top: 15px;
          }
        }
        // 列表
        .tcbList {
          width: 1210px;
          margin: 0 auto;
          height: 500px;
          margin-top: 30px;
          ul {
            width: 100%;
            height: 100%;
            display: flex;
            justify-content: space-around;
            flex-wrap: wrap;
            .tblLi {
              width: 32%;
              height: 478px;
              border-radius: 10px;
              transition: 0.5s;
              background-color: white;
              box-shadow: 1px 1px 10px 1px gainsboro;
              // 列表头部
              .tl1 {
                width: 100%;
                height: 128px;
                overflow: hidden;
                position: relative;
                .tl1Bg {
                  width: 100%;
                  height: 100%;
                  position: absolute;
                  top: 0;
                }
                .tl1p {
                  width: 90%;
                  height: 28px;
                  line-height: 28px;
                  font-size: 18px;
                  position: absolute;
                  top: 20px;
                  left: 20px;
                  color: white;
                }
                .tl1bs {
                  width: 90%;
                  height: 22px;
                  position: absolute;
                  top: 70px;
                  left: 0px;
                  .tl1b {
                    width: 66px;
                    height: 22px;
                    color: white;
                    font-size: 12px;
                    border: 1px solid white;
                    text-align: center;
                    line-height: 22px;
                    float: left;
                    margin-left: 20px;
                    border-radius: 5px;
                  }
                }
              }
              // 选项
              .tl2 {
                width: 90%;
                height: 190px;
                margin: 0 auto;
                .tl21 {
                  width: 100%;
                  height: 152px;
                  display: flex;
                  justify-content: space-around;
                  flex-wrap: wrap;
                  dt {
                    width: 100%;
                    height: 24px;
                    .dtImg {
                      width: 14px;
                      height: 14px;
                      float: left;
                      margin-top: 5px;
                    }
                    .dtp {
                      min-width: 140px;
                      height: 24px;
                      line-height: 24px;
                      float: left;
                      margin-left: 10px;
                    }
                  }
                }
                .tl22 {
                  width: 100%;
                  height: 40px;
                  .t22p {
                    width: 56px;
                    height: 38px;
                    line-height: 38px;
                    font-size: 12px;
                    color: gray;
                    float: left;
                  }
                  .t22Ip {
                    width: 260px;
                    height: 38px;
                    float: left;
                    margin-left: 25px;
                    select {
                      width: 100%;
                      height: 100%;
                    }
                  }
                }
              }
              // 价格
              .tl3 {
                width: 90%;
                height: 120px;
                margin: 0 auto;
                margin-top: 20px;
                overflow: hidden;
                .tl31 {
                  width: 75px;
                  height: 24px;
                  line-height: 24px;
                  color: white;
                  border-radius: 5px;
                  text-align: center;
                  font-size: 12px;
                  background-color: #f33e3e;
                  margin-top: 5px;
                }
                .tl32 {
                  width: 100%;
                  height: 32px;
                  line-height: 32px;
                  margin-top: 5px;
                  .tl3p {
                    min-width: 100px;
                    height: 32px;
                    font-size: 20px;
                    float: left;
                    color: #f43f3f;
                  }
                  .tl3p2 {
                    width: 100px;
                    height: 24px;
                    margin-top: 8px;
                    line-height: 24px;
                    font-size: 14px;
                    float: left;
                    color: gray;
                    text-decoration: line-through;
                    margin-left: 10px;
                  }
                }
                .tl33 {
                  width: 100%;
                  height: 40px;
                  margin-top: 5px;
                  .tl3b {
                    width: 158px;
                    height: 40px;
                    line-height: 40px;
                    text-align: center;
                    color: #f43f3f;
                    font-size: 14px;
                    border-radius: 5px;
                    border: 1px solid #f43f3f;
                    float: left;
                    margin-left: 10px;
                  }
                }
              }
            }
            .tblLi:hover {
              transform: translateY(-15px);
            }
          }
        }
        .tcbBottom {
          width: 100%;
          height: 350px;
          .tcbp {
            width: 100%;
            height: 28px;
            line-height: 28px;
            font-size: 20px;
            color: #1e3f82;
            text-align: center;
          }
          .tbList {
            width: 1210px;
            margin: 0 auto;
            height: 185px;
            margin-top: 20px;
            ul {
              width: 100%;
              height: 100%;
              display: flex;
              justify-content: space-around;
              .tbLi {
                width: 23%;
                height: 100%;
                border-radius: 5px;
                overflow: hidden;
                box-shadow: 1px 1px 10px 1px gainsboro;
                background: url("https://cbs-public.cdn.bcebos.com/portal/20220623/advantage-item-bg.png");
                .tblBox {
                  width: 90%;
                  height: 90%;
                  margin: 0 auto;
                  margin-top: 10px;
                  overflow: hidden;
                  .tb1 {
                    width: 100%;
                    height: 40px;
                    margin-top: 10px;
                    .tb1Img {
                      width: 40px;
                      height: 40px;
                      display: block;
                      margin: 0 auto;
                    }
                  }
                  .tb2 {
                    width: 100%;
                    height: 28px;
                    line-height: 28px;
                    font-size: 18px;
                    text-align: center;
                    margin-top: 10px;
                  }
                  .tb3 {
                    width: 100%;
                    height: 50px;
                    line-height: 24px;
                    text-align: center;
                    font-size: 14px;
                    margin-top: 10px;
                  }
                }
              }
            }
          }
          .tcbBtn {
            width: 170px;
            height: 40px;
            text-align: center;
            background-color: #2469f3;
            border-radius: 10px;
            margin: 0 auto;
            margin-top: 40px;
            .tbp {
              width: 72px;
              height: 20px;
              line-height: 20px;
              color: white;
              font-size: 12px;
              float: left;
              margin-top: 10px;
              margin-left: 35px;
            }
            .tbIcon {
              width: 20px;
              height: 20px;
              margin-left: 10px;
              float: left;
              margin-top: 10px;
            }
          }
        }
      }
      // 流程图
      .tc2Pic {
        width: 1210px;
        margin: 0 auto;
        height: 336px;
        .t2pBox {
          width: 90%;
          height: 100%;
          margin: 0 auto;
          overflow: hidden;
          .t2pTop {
            width: 100%;
            height: 80px;
            text-align: center;
            margin-top: 30px;
            .tpt1 {
              width: 100%;
              height: 28px;
              line-height: 28px;
              color: #1e3f82;
              font-size: 28px;
              font-weight: 900;
            }
            .tpt2 {
              width: 100%;
              height: 24px;
              line-height: 24px;
              font-size: 14px;
              color: #1e3f82;
              margin-top: 20px;
            }
          }
          .t2pImg {
            width: 100%;
            height: 136px;
            margin-top: 50px;
            .t2pI {
              width: 100%;
              height: 100%;
            }
          }
        }
      }
    }
  }
}
</style>
